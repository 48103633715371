import { api } from '@piggy/lib';
export class PiggyApiError extends Error {
    status;
    statusText;
    data;
    message;
    constructor(status, statusText, data) {
        super(`PiggyApiError: ${statusText}`);
        this.status = status;
        this.statusText = statusText;
        this.data = data;
        this.message = data;
    }
}
export class PiggyFrontendService {
    async getShops() {
        const { data, error } = await api.get('/piggy/private/shops');
        if (error ?? !data) {
            if (error) {
                throw new PiggyApiError(error.status, error.statusText, error.data);
            }
            throw new Error('No data returned');
        }
        return data;
    }
    async getRewards() {
        const { data, error } = await api.get('/piggy/private/rewards');
        if (error ?? !data) {
            if (error) {
                throw new PiggyApiError(error.status, error.statusText, error.data);
            }
            throw new Error('No data returned');
        }
        return data;
    }
    async getCoupons(userId) {
        const { data, error } = await api.get(`/piggy/v1/coupons?userId=${userId}`);
        if (error) {
            throw new PiggyApiError(error.status, error.statusText, error.data);
        }
        return data;
    }
    async claimReward(earnRuleId, userId) {
        const { data, error } = await api.post('/piggy/v1/earn-reward', {
            userId,
            earnRuleId
        });
        if (error) {
            if (error) {
                throw new PiggyApiError(error.status, error.statusText, error.data);
            }
            throw new Error('No data returned');
        }
        return data;
    }
    async joinProgram(userId) {
        const { data, error } = await api.post('/piggy/v1/join-program?g', {
            userId
        });
        if (error) {
            throw new PiggyApiError(error.status, error.statusText, error.data);
        }
        return data;
    }
    async claimSpendRule(spendRuleId, userId) {
        const { data, error } = await api.post(`/piggy/v1/spend-rules-claim`, {
            userId,
            id: spendRuleId
        });
        if (error) {
            if (error) {
                throw new PiggyApiError(error.status, error.statusText, error.data);
            }
            throw new Error('No data returned');
        }
        return data;
    }
}
export const apiService = new PiggyFrontendService();
