<script>import { MutationCache, QueryClient, QueryClientProvider } from "@tanstack/svelte-query";
import { SvelteQueryDevtools } from "@tanstack/svelte-query-devtools";
import { __ } from "@wordpress/i18n";
import { isDev } from "./config/environment";
import { WooCommerceStoreApiError } from "./modules/cart/cart.service";
import {
  pluginSettings as settingsState,
  wcSettings as wcSettingsState
} from "./modules/settings";
import { hooks } from "./utils/hooks";
import { onMount } from "svelte";
import DashboardHeaderPts from "./components/dashboard-header-pts.svelte";
import "./global.css";
import DashboardCoupons from "./components/dashboard-coupons.svelte";
import DashboardEarn from "./components/dashboard-earn.svelte";
import DashboardRewards from "./components/dashboard-rewards.svelte";
export let wcSettings;
export let pluginSettings;
wcSettingsState.set(wcSettings);
settingsState.set(pluginSettings);
const mutationCache = new MutationCache({
  onError: (error) => {
    if (error instanceof WooCommerceStoreApiError) {
      const { message, status, statusText } = error;
      return;
    }
  }
});
onMount(async () => {
  hooks.doAction("on.init", $settingsState);
});
const queryClient = new QueryClient({
  mutationCache,
  defaultOptions: {
    queries: {
      staleTime: 2 * 60 * 1e3
    }
  }
});
</script>

<QueryClientProvider client={queryClient}>
	{#if $settingsState}
		{#if $settingsState.plugin_enable}
			<div class="piggy-dashboard">
				<DashboardHeaderPts />
				<DashboardCoupons />
				<DashboardEarn />
				<DashboardRewards />
			</div>
		{/if}
	{/if}

	{#if isDev}
		<SvelteQueryDevtools />
	{/if}
</QueryClientProvider>

<style>
	.piggy-dashboard {
		background-color: white;
		font-size: 16px;
		border-radius: 6px;
		max-width: 1260px;
		margin: 0 auto;
	}

	@media (min-width: 768px) {
		.piggy-dashboard {
			padding: 16px;
		}
	}

	.piggy-dashboard {
		margin-left: auto;
		margin-right: auto;
		height: 100%;
		max-width: 1260px;
		padding: 80px 40px;
	}
</style>
